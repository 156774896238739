import BaseModel from "./BaseModel";
import { EntityTypeId } from "../util/EntityTypeId";
import OrphyNetwork from "../network/orphyNetwork";

export default class AppModel extends BaseModel {
    constructor() {
        super(new OrphyNetwork());
    }

    protected contactNoteBoundToMail(uuid: string): boolean {
        return false;
    }

    protected logoutInvisible(): boolean {
        return true;
    }

    protected todoBoundToMail(uuid: string): boolean {
        return false;
    }

    protected async addContactNoteCategory(uuid: string) {
        Promise.resolve();
    }

    protected async addTodoCategory(uuid: string) {
        Promise.resolve();
    }

    protected async getSubject(): Promise<string> {
        return "";
    }

    protected async getEmailAddress(): Promise<string> {
        return "";
    }

    public reloadPlugin = async () => {
        await this.refreshPlugin();
    };

    protected async setAttachments(): Promise<void> {}

    protected async handleAttachmentUpload(uuid: string, entityTypeId: EntityTypeId): Promise<boolean> {
        return false;
    }

    protected ccAndToButtonsVisible(): boolean {
        return false;
    }

    protected addParticipantButtonVisible(): boolean {
        return false;
    }
}
