import MeEntity from "../Entities/MeEntity";
import Network from "../network/network";
import CompanyEntity from "../Entities/CompaniesEntity";
import Util from "../util/Util";
import MeService from "../services/MeService";
import AkquiseTypeService from "../services/AkuiseTypeService";
import AvatarService from "../services/AvatarService";
import CurrencyService from "../services/CurrencyService";
import InstitutionAddressService from "../services/InstitutionAddressService";
import InstitutionContactService from "../services/InstitutionContactService";
import InstitutionService from "../services/InstitutionService";
import PersonAddressService from "../services/PersonAddressService";
import PersonContactService from "../services/PersonContactService";
import PersonService from "../services/PersonService";
import PersonToInstitutionRelationService from "../services/PersonToInstitutionRelationService";
import SenderModelService from "../services/SenderModelService";
import AttachementService from "../services/AttachementService";
import AkquiseService from "../services/AkquiseService";
import LeadService from "../services/LeadService";
import TodoService from "../services/TodoService";
import LeadModel from "../models/LeadModel";
import PersonToInstitutionRelationModel from "../models/PersonToInstitutionRelationModel";
import SenderModel from "../models/SenderModel";
import LeadEntity from "../Entities/LeadEntity";
import CurrencyEntity from "../Entities/CurrencyEntity";
import TodoModel from "../models/TodoModel";
import TodoEntity from "../Entities/TodoEntity";
import ContactNoteModel from "../models/contactNoteModel";
import AkquiseTypeEntity from "../Entities/AkquiseTypeEntity";
import { EntityTypeId } from "../util/EntityTypeId";
import Logger from "../util/Logger";
import { Loglevel } from "../util/Logger";
import ContactNoteEntity from "../Entities/ContactNoteEntity";
import SearchService from "../services/SearchService";
import PersonToInstitutionRelationEntity from "../Entities/PersonToInstitutionRelationEntity";
import PersonToInstRelationTypeEntity from "../Entities/PersonToInstRelationTypeEntity";
import ContactMediaService from "../services/ContactMediaService";
import IApiSearchResult from "../interfaces/IApiSearchResult";
import Translation from "../services/TranslationService";

export default abstract class BaseModel extends kendo.data.ObservableObject {
    protected initialized: boolean;
    protected meService: MeService;
    protected network: Network;
    protected logger: Logger;
    protected ptirService: PersonToInstitutionRelationService;
    protected personService: PersonService;
    protected institutionService: InstitutionService;
    protected akquiseTypeService: AkquiseTypeService;
    protected contactNoteService: AkquiseService;
    protected senderModelService: SenderModelService;
    protected currencyService: CurrencyService;
    protected avatarService: AvatarService;
    protected todoService: TodoService;
    protected attachementService: AttachementService;
    protected leadService: LeadService;
    protected searchService: SearchService;
    protected contactMediaTypeService: ContactMediaService;

    private meEntity: MeEntity;

    private companyChooserDialog: kendo.ui.Confirm;
    private personInstitutionChooser: kendo.ui.Confirm;

    protected selectedContactNotesLeadId: number;
    protected selectedTodoLeadId: number;
    private contactNotevalidator: kendo.ui.Validator;
    private todoValidator: kendo.ui.Validator;
    private token: any;
    private currencies: CurrencyEntity[];
    private akquiseTypes: AkquiseTypeEntity[];

    private leadSearchEnabledForContactNotes: boolean;
    private leadSearchEnabledForTodos: boolean;
    private static translation = new Translation();
    private searchTimeout = null;

    public get<T>(name: string): T {
        return super.get(name) as T;
    }

    public readonly PAGE_SIZE = 15;

    public newContactLink: string = `${Network.BaseURL}PersonDetail/Details/0?editMode=True`;
    public newLeadLink: string = `${Network.BaseURL}LeadDetail/Details/0?editMode=True`;
    public newTodoLink: string = `${Network.BaseURL}TodoDetail/Details/0?editMode=True`;

    constructor(network: Network) {
        super();
        this.network = network;
        this.initServices();
        this.addListener();
        this.resetDefaultValues();
        super.init(this);
    }

    protected abstract getSubject(): Promise<string>;

    protected abstract getEmailAddress(): Promise<string>;

    protected abstract setAttachments(): Promise<void>;

    protected abstract handleAttachmentUpload(uuid: string, entityTypeId: EntityTypeId): Promise<boolean>;

    protected abstract ccAndToButtonsVisible(): boolean;

    protected abstract addParticipantButtonVisible(): boolean;

    protected abstract addContactNoteCategory(uuid: string);
    protected abstract contactNoteBoundToMail(uuid: string): boolean;

    protected abstract addTodoCategory(uuid: string);
    protected abstract todoBoundToMail(uuid: string): boolean;

    protected logoutInvisible(): boolean {
        return false;
    }

    public initServices(): void {
        this.logger = new Logger(this.network);

        this.meService = new MeService(this.network);
        this.avatarService = new AvatarService(this.network, this.logger);

        this.akquiseTypeService = new AkquiseTypeService(this.network, this.logger);
        this.currencyService = new CurrencyService(this.network, this.logger);
        this.attachementService = new AttachementService(this.network, this.logger);

        const personContactService = new PersonContactService(this.network, this.logger);
        const personAddressService = new PersonAddressService(this.network, this.logger);

        const institutionContactService = new InstitutionContactService(this.network, this.logger);
        const institutionAddressService = new InstitutionAddressService(this.network, this.logger);

        this.todoService = new TodoService(this.network, this.logger);
        this.leadService = new LeadService(this.network, this.currencyService, this.logger);

        this.contactNoteService = new AkquiseService(this.network, this.logger);

        this.contactMediaTypeService = new ContactMediaService(this.network, this.logger);
        this.ptirService = new PersonToInstitutionRelationService(this.network, this.contactMediaTypeService, this.logger);

        this.personService = new PersonService(
            this.network,
            personContactService,
            personAddressService,
            this.avatarService,
            this.contactMediaTypeService,
            this.logger
        );
        this.institutionService = new InstitutionService(
            this.network,
            institutionContactService,
            institutionAddressService,
            this.avatarService,
            this.contactMediaTypeService,
            this.logger
        );
        this.senderModelService = new SenderModelService(this.personService, this.institutionService, this.ptirService);

        this.searchService = new SearchService(this.network, this.logger);
    }

    public async init(): Promise<void> {
        await BaseModel.translation.init();
    }

    public login = () => {
        this.network.signout();
        this.chooseCompany();
    };

    public getMe = async (): Promise<MeEntity> => {
        if (this.meEntity) {
            return this.meEntity;
        } else {
            this.meEntity = await this.meService.getMe();
            return this.meEntity;
        }
    };

    public async load(email?: string, personId?: number, institutionId?: number) {
        this.companyChooserDialog?.close();
        this.personInstitutionChooser?.close();
        ($("#orphySaveMail") as any).modal("hide");
        ($("#orphyTodo") as any).modal("hide");
        this.set("searchVisible", false);
        this.set("relationChooserVisible", false);
        this.set("hasAttachedTodoLead", false);

        BaseModel.localize();

        this.set("todoPriorities", this.getTodoPriorites());
        this.set("reminderFactor", this.getReminderFactor());
        this.set("smilies", this.getSmilies());

        try {
            Util.showLoading();
            this.resetDefaultValues();

            this.token = await this.network.authenticate();

            this.setListViewHeight();

            this.akquiseTypes = await this.akquiseTypeService.getItems();
            this.currencies = await this.currencyService.getItems();

            let emailAddress = email ? email : await this.getEmailAddress();
            this.set("emailAddress", emailAddress);
            this.set("personId", personId);
            this.set("institutionId", institutionId);
            this.setNewLinks(emailAddress);
            await this.loadData(emailAddress, personId, institutionId);
            this.set("globalSearchDataSource", this.getSearchDataSource());
            $("#searchListView")
                .kendoMobileListView({
                    dataSource: this.get("globalSearchDataSource"),
                    template: $("#searchTemplate").html(),
                    click: this.manualSelectContact,
                    dataBound: this.searchListViewDataBound,
                })
                .data().kendoMobileListView;

            this.set("senderVisible", !!this.get("sender.Person") || !!this.get("sender.Institution"));
            this.set("searchVisible", !this.get("senderVisible"));
            this.set("showContent", true);

            this.selectedContactNotesLeadId = null;
            this.selectedTodoLeadId = null;

            this.set(
                "filterInstLeads",
                this.get("sender") && this.get<SenderModel>("sender").HasInstitution ? this.get<SenderModel>("sender").HasInstitution : false
            );
            this.set("openLeadsForContactNotes", this.getOpenLeadDataSource(this.getFilterForLeadsInContactNotes));
            this.set("openLeadsForTodos", this.getOpenLeadDataSource(this.getFilterForLeadsinTodos));

            this.set("todoVerantwortliche", this.getVerantwortlicheDataSource());
            await this.setAttachments();
            await this.setInitialContactNotesGuiValues();
            await this.setInitialTodoGuiValues();
            await this.setInitialLeadGuiValues();
            this.set("aTypes", this.akquiseTypes);
            this.contactNotevalidator = $("#contactNoteInputWrapper").kendoValidator().data().kendoValidator;
            this.todoValidator = $("#todoInputWrapper").kendoValidator().data().kendoValidator;
            $("#tabs").data("kendoTabStrip").select(0);
            BaseModel.localize();
            Util.hideLoading();
        } catch (e) {
            this.logger.log(Loglevel.Info, `No contact with email address ${this.get("emailAddress")} found in orphy`, e);
            $("#tabs").data("kendoTabStrip").select(0);
            this.set("showContent", true);
            Util.hideLoading();
            this.showSearchView();
        }
    }

    public static localize = () => {
        try {
            BaseModel.translation.localize("body");
        } catch (e) {
            console.error(`could not localize body: ${e}`);
        }
    };

    public static t(key: string, args?: any): string {
        try {
            return BaseModel.translation.t(key, args) as string;
        } catch (e) {
            console.error(`could not translate key: ${args} ${e}`);
            return "";
        }
    }

    private getTodoPriorites() {
        return [
            { text: BaseModel.t("priority-non"), value: "Prio0" },
            { text: BaseModel.t("priority-very-low"), value: "Prio1" },
            { text: BaseModel.t("priority-low"), value: "Prio2" },
            { text: BaseModel.t("priority-medium"), value: "Prio3" },
            { text: BaseModel.t("priority-high"), value: "Prio4" },
            { text: BaseModel.t("priority-very-high"), value: "Prio5" },
        ];
    }

    private getReminderFactor = () => {
        return [
            { name: BaseModel.t("minute", { count: 0 }), factor: 60 },
            { name: BaseModel.t("hour", { count: 0 }), factor: 60 * 60 },
            { name: BaseModel.t("day", { count: 0 }), factor: 60 * 60 * 24 },
            { name: BaseModel.t("week", { count: 0 }), factor: 60 * 60 * 24 * 7 },
        ];
    };

    private getSmilies = () => [
        { smiley: "happy", value: "Happy" },
        { smiley: "neutral", value: "Neutral" },
        { smiley: "sad", value: "Unhappy" },
    ];

    private addListener = () => {
        $("#searchListView").on("click", ".searchListViewHeaderSelector", this.hideSearchGroup);
    };

    public reloadPlugin = async () => {
        await this.load();
    };

    public refreshPlugin = async () => {
        await this.load(this.get("emailAddress"), this.get("personId"), this.get("institutionId"));
    };

    public openOrphyNew = e => {
        window.open($(e.currentTarget).data("link"), "_blank");
    };

    protected getCreateContactNoteText = (): string => {
        return "create-contact-note";
    };

    public toggleCreateContactNote = () => {
        this.set("createContactNoteVisible", !this.get("createContactNoteVisible"));
    };

    public toggleCreateTodo = () => {
        this.set("createTodoVisible", !this.get("createTodoVisible"));
    };

    public reminderActiveChanged = (): void => {
        if (!this.get("reminderActive")) {
            this.set("reminderSeconds", 0);
        }
    };

    public chooseCompany = async (): Promise<void> => {
        let me = await this.getMe();
        if (!this.get("initialized")) {
            if (me.Companies.length > 1) {
                const companyId = await this.selectCompany(me.Companies, me.CompanyId);
                if (me.CompanyId !== companyId) {
                    await this.meService.switchCompany(companyId);
                }
            }
            this.set("initialized", true);
            await this.load();
        }
        await this.getMe();
    };

    private selectCompany = (companies: CompanyEntity[], currentCompanyId: number): Promise<number> => {
        $("body").append("<div id='confirmTemplate'></div>");
        let content = "<select style='width:100%'>";
        companies.forEach(c => (content += `<option ${currentCompanyId === c.Id ? "selected" : ""} value=${c.Id}>${c.Name}</option>`));
        content += "</select>";
        return new Promise(res => {
            this.companyChooserDialog = $("#confirmTemplate")
                .kendoConfirm({
                    title: BaseModel.t("choose-company"),
                    content: content,
                })
                .data("kendoConfirm");

            return this.companyChooserDialog
                .open()
                .result.done(() => res(Number($("#confirmTemplate").find(":selected").val())))
                .fail(() => res(Util.firstOrDefault(companies).Id))
                .always(() => $("#confirmTemplate").remove());
        });
    };

    public signout = () => {
        this.network.signout();
        this.set("initialized", false);
    };

    private setListViewHeight = () => {
        const height = $("#app-body").height() - $("#tabs > ul").height() - $(".headerTitle").height() - 2; //-5 prevent double scrollbar on reload ?!
        $("#contactNoteListView").css("height", height);
        $("#leadListView").css("height", height);
        $("#todoListView").css("height", height);
        $("#searchListView").css("height", height - 60);
    };

    private getOpenLeadDataSource = (getFilterFunction: Function) => {
        return kendo.data.DataSource.create({
            type: "odata-v4",
            pageSize: this.PAGE_SIZE,
            serverPaging: true,
            serverFiltering: true,
            transport: {
                read: {
                    url: Network.OdataBaseURL + LeadService.ODATA_ROUTE + "?$expand=Person,ContactPerson,Institution",
                    beforeSend: req => {
                        req.setRequestHeader("Authorization", this.network.getAuthorizationHeader(this.token));
                        req.setRequestHeader("pragma", "no-cache");
                        req.setRequestHeader("content-type", "application/json");
                        req.setRequestHeader("cache-control", "no-cache");
                    },
                },
            },
            schema: {
                data: response => {
                    return response.value.map(l => new LeadModel(LeadEntity.fromOdata(l, new LeadEntity()), this.currencies));
                },
            },
            filter: getFilterFunction(),
        });
    };

    private getLeadDataSource = (filter: string) => {
        return kendo.data.DataSource.create({
            type: "odata-v4",
            pageSize: this.PAGE_SIZE,
            serverPaging: true,
            serverFiltering: true,
            transport: {
                read: {
                    url: Network.OdataBaseURL + LeadService.ODATA_ROUTE + `?$expand=Person,ContactPerson,Institution&$filter=${filter}`,
                    beforeSend: req => {
                        req.setRequestHeader("Authorization", this.network.getAuthorizationHeader(this.token));
                        req.setRequestHeader("pragma", "no-cache");
                        req.setRequestHeader("cache-control", "no-cache");
                    },
                },
            },
            schema: {
                data: response => {
                    return response.value.map(l => new LeadModel(LeadEntity.fromOdata(l, new LeadEntity()), this.currencies));
                },
            },
        });
    };

    private getTodoDataSource = (filter: string) => {
        return kendo.data.DataSource.create({
            type: "odata-v4",
            pageSize: this.PAGE_SIZE,
            serverPaging: true,
            serverFiltering: true,
            transport: {
                read: {
                    url:
                        Network.OdataBaseURL +
                        TodoService.ODATA_ROUTE +
                        `?$expand=Persons($select=Id,DisplayName),Institutions($select=Id,Name),PersonToInstitutionRelations($expand=Person($select=Id,DisplayName),Institution($select=Id,Name),Relation)` +
                        `&$filter=${filter}`,
                    beforeSend: req => {
                        req.setRequestHeader("Authorization", this.network.getAuthorizationHeader(this.token));
                        req.setRequestHeader("pragma", "no-cache");
                        req.setRequestHeader("cache-control", "no-cache");
                    },
                },
            },
            schema: {
                data: response => {
                    return response.value.map(t => {
                        const model = new TodoModel(TodoEntity.fromOdata(t, new TodoEntity()));
                        model.IsBoundToMail = this.todoBoundToMail(t.Uuid);
                        return model;
                    });
                },
            },
        });
    };

    private getContactNoteDataSource = (filter: string) => {
        return kendo.data.DataSource.create({
            type: "odata-v4",
            pageSize: this.PAGE_SIZE,
            serverPaging: true,
            serverFiltering: true,
            transport: {
                read: {
                    url:
                        Network.OdataBaseURL +
                        AkquiseService.ODATA_ROUTE +
                        `?$expand=Persons($select=Id,DisplayName),Institutions($select=Id,Name),PersonToInstitutionRelations($expand=Person($select=Id,DisplayName),Institution($select=Id,Name)),Lead($expand=Person,Institution,ContactPerson)` +
                        `&$filter=${filter}` +
                        `&$orderby=Date desc`,
                    beforeSend: req => {
                        req.setRequestHeader("Authorization", this.network.getAuthorizationHeader(this.token));
                        req.setRequestHeader("pragma", "no-cache");
                        req.setRequestHeader("cache-control", "no-cache");
                    },
                },
            },
            schema: {
                data: response => {
                    return response.value.map(c => {
                        const entity = ContactNoteEntity.fromOdata(c, new ContactNoteEntity());
                        const model = new ContactNoteModel(
                            entity,
                            this.akquiseTypes.find(a => a.Id === entity.ContactTypeId),
                            entity.Lead ? new LeadModel(entity.Lead, this.currencies) : null
                        );
                        model.IsBoundToMail = this.contactNoteBoundToMail(c.Uuid);
                        return model;
                    });
                },
            },
        });
    };

    private getVerantwortlicheDataSource = () => {
        return kendo.data.DataSource.create({
            type: "odata-v4",
            transport: {
                read: {
                    url: Network.OdataBaseURL + "user" + "?$select=Id,DisplayName",
                    beforeSend: req => {
                        req.setRequestHeader("Authorization", this.network.getAuthorizationHeader(this.token));
                    },
                },
            },
            schema: {
                data: response => {
                    return response.value;
                },
            },
        });
    };

    protected getSearchDataSource = () => {
        return kendo.data.DataSource.create({
            transport: {
                parameterMap: () => {
                    return JSON.stringify(this.getSearchDataSourceParameters());
                },
                read: {
                    url: `${Network.APIBaseURL}${SearchService.API_ROUTE}`,
                    data: this.getSearchDataSourceParameters,
                    contentType: "application/json",
                    dataType: "json",
                    type: "POST",
                    beforeSend: req => {
                        req.setRequestHeader("Authorization", this.network.getAuthorizationHeader(this.token));
                        req.setRequestHeader("pragma", "no-cache");
                        req.setRequestHeader("cache-control", "no-cache");
                    },
                    complete: async (items: JQuery.jqXHR<any>) => {
                        const response: IApiSearchResult[] = items.responseJSON;
                        const personRelations = await Promise.all(
                            response
                                .filter(sr => sr.EntityTypeId === EntityTypeId.PersonEntity)
                                .map(async sr => {
                                    return this.ptirService.getRelationsByPersonId(sr.EntityId);
                                })
                        );
                        $("#searchListView")
                            .data()
                            .kendoMobileListView.dataSource.data()
                            .forEach(sr => {
                                const relation =
                                    Util.firstOrDefault(personRelations.find(x => x.find(y => y.IsMainInstitution && y.PersonId === sr.EntityId))) ||
                                    Util.firstOrDefault(personRelations.find(x => x.find(y => y.PersonId === sr.EntityId)));
                                if (relation) {
                                    sr.set("RelationName", relation.RelationName);
                                }
                            });
                    },
                },
            },
            schema: {
                data: response => {
                    return response
                        .map((r: IApiSearchResult) => this.searchService.mapDtoToEntity(r))
                        .filter(r => r.EntityTypeId === EntityTypeId.PersonEntity || r.EntityTypeId == EntityTypeId.InstitutionEntity);
                },
            },
        });
    };

    protected getSearchDataSourceParameters = () => {
        return {
            search: this.get("globalFilter"),
            entityTypesToSearch: [EntityTypeId.PersonEntity, EntityTypeId.InstitutionEntity],
        };
    };

    private getFilterForLeadsinTodos = () => {
        if (!this.get("hasAttachedTodoLead") || this.leadSearchEnabledForTodos) {
            const filter = {
                logic: "and",
                filters: [
                    {
                        field: "EOverallState",
                        operator: "eq",
                        value: "None",
                    },
                    {
                        logic: "or",
                        filters: [
                            {
                                field: "Titel",
                                operator: "contains",
                                value: this.get("leadSearchTodos"),
                            },
                            {
                                field: "Person/DisplayName",
                                operator: "contains",
                                value: this.get("leadSearchTodos"),
                            },
                            {
                                field: "Institution/Name",
                                operator: "contains",
                                value: this.get("leadSearchTodos"),
                            },
                            {
                                field: "ContactPerson/DisplayName",
                                operator: "contains",
                                value: this.get("leadSearchTodos"),
                            },
                        ],
                    },
                ],
            };
            if (this.get("filterInstLeads")) {
                filter.filters.push({
                    field: "Institution/Id",
                    operator: "eq",
                    value: this.get<SenderModel>("sender").Institution.Id as any,
                });
            }
            return filter;
        }

        return {
            field: "Id",
            operator: "eq",
            value: this.selectedTodoLeadId,
        };
    };

    private getFilterForLeadsInContactNotes = () => {
        if (!this.get("hasAttachedContactLead") || this.leadSearchEnabledForContactNotes) {
            const filter = {
                logic: "and",
                filters: [
                    {
                        field: "EOverallState",
                        operator: "eq",
                        value: "None",
                    },
                    {
                        logic: "or",
                        filters: [
                            {
                                field: "Titel",
                                operator: "contains",
                                value: this.get("leadSearchContactNotes"),
                            },
                            {
                                field: "Person/DisplayName",
                                operator: "contains",
                                value: this.get("leadSearchContactNotes"),
                            },
                            {
                                field: "Institution/Name",
                                operator: "contains",
                                value: this.get("leadSearchContactNotes"),
                            },
                            {
                                field: "ContactPerson/DisplayName",
                                operator: "contains",
                                value: this.get("leadSearchContactNotes"),
                            },
                        ],
                    },
                ],
            };
            if (this.get("filterInstLeads")) {
                filter.filters.push({
                    field: "Institution/Id",
                    operator: "eq",
                    value: this.get<SenderModel>("sender").Institution.Id as any,
                });
            }
            return filter;
        }

        return {
            field: "Id",
            operator: "eq",
            value: this.selectedContactNotesLeadId,
        };
    };

    private resetDefaultValues() {
        this.set("sender", new SenderModel(null, null, null, null, null));
        this.set("relationName", "");
        this.set("createContactNoteText", this.getCreateContactNoteText());
        this.set("globalFilter", "");
        this.set("hasAttachedContactLead", false);
        this.set("leadSearchTodos", "");
        this.set("leadSearchContactNotes", "");
    }

    public showSearchView() {
        this.set("globalFilter", "");
        this.set("senderVisible", false);
        this.set("searchVisible", true);
    }

    public searchGlobal = async e => {
        if (this.get("globalFilter") != e.currentTarget.value) {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                this.set("globalFilter", e.currentTarget.value);
                kendo.ui.progress($("#searchListView"), true);
                this.get<kendo.data.DataSource>("globalSearchDataSource").read();
            }, 1000);
        }
    };

    public searchOpenLeadForContactNotes = e => {
        this.set("leadSearchContactNotes", e.currentTarget.value);
        this.leadSearchEnabledForContactNotes = true;
        this.get<any>("openLeadsForContactNotes").filter(this.getFilterForLeadsInContactNotes());
    };

    public searchOpenLeadForTodos = e => {
        this.set("leadSearchTodos", e.currentTarget.value);
        this.leadSearchEnabledForTodos = true;
        this.get<any>("openLeadsForTodos").filter(this.getFilterForLeadsinTodos());
    };

    public attachLeadToContactNotes = e => {
        this.set("hasAttachedContactLead", true);
        this.selectedContactNotesLeadId = e.data.Id;
        this.leadSearchEnabledForContactNotes = false;
        this.get<any>("openLeadsForContactNotes").filter(this.getFilterForLeadsInContactNotes());
    };

    public detachLeadForContactNotes = e => {
        this.set("hasAttachedContactLead", false);
        this.selectedContactNotesLeadId = null;
        this.leadSearchEnabledForContactNotes = false;
        this.get<any>("openLeadsForContactNotes").filter(this.getFilterForLeadsInContactNotes());
    };

    public attachTodoLead = e => {
        this.set("hasAttachedTodoLead", true);
        this.selectedTodoLeadId = e.data.Id;
        this.leadSearchEnabledForTodos = false;
        this.get<any>("openLeadsForTodos").filter(this.getFilterForLeadsinTodos());
    };

    public detachTodoLead = e => {
        this.set("hasAttachedTodoLead", false);
        this.selectedTodoLeadId = null;
        this.leadSearchEnabledForTodos = false;
        this.get<any>("openLeadsForTodos").filter(this.getFilterForLeadsinTodos());
    };

    private relationChooser = async (ptir: PersonToInstitutionRelationModel[]): Promise<PersonToInstitutionRelationModel> => {
        if (ptir.length === 1) {
            return Util.firstOrDefault(ptir);
        }
        this.set("relationChooserVisible", true);
        this.set("showContent", true);
        $("#tabs").data("kendoTabStrip").select(0);
        this.set("senderVisible", false);
        const lv = $("#relationListView").data("kendoMobileListView");
        if (lv && lv.destroy) {
            lv.destroy();
        }

        for (const p of ptir) {
            p.Avatar = await this.avatarService.getAvatar(p.PersonId, EntityTypeId.PersonEntity);
        }

        return new Promise(res => {
            $("#relationListView")
                .kendoMobileListView({
                    dataSource: ptir,
                    template: $("#relationChooserTemplate").html(),
                    click: e => {
                        this.set("relationChooserVisible", false);
                        res(e.dataItem);
                    },
                })
                .data().kendoMobileListView;
            Util.hideLoading();
        });
    };

    public createNote = async () => {
        if (this.contactNotevalidator.validate()) {
            Util.showLoading();
            ($("#orphySaveMail") as any).modal("toggle");
            const sender = this.getSender();
            // no selected lead but a lead to create
            if (!this.selectedContactNotesLeadId && this.get("contactNoteLeadCreateTitle") && this.get("contactNoteLeadCreateVerantwortlich")) {
                let lead = await this.createLeadEntityFromContactNote(sender);
                this.selectedContactNotesLeadId = lead.Id;
            }

            let contactNote = await this.createContactNoteEntity();

            let hasAttachmentError = await this.handleAttachmentUpload(contactNote.Uuid, EntityTypeId.Akquise);

            await this.createNoteRefLinks(sender, contactNote);

            await this.addContactNoteCategory(contactNote.Uuid);

            this.toggleCreateContactNote();

            await this.refreshPlugin();

            this.showContactNoteCreatedNotification(hasAttachmentError);
        }
    };

    public createTodo = async () => {
        if (this.todoValidator.validate()) {
            Util.showLoading();
            ($("#orphyTodo") as any).modal("toggle");
            const sender = this.getSender();

            // no selected lead but a lead to create
            if (!this.selectedTodoLeadId && this.get("todoLeadCreateTitle") && this.get("todoLeadCreateVerantwortlich")) {
                let lead = await this.createLeadEntityFromTodo(sender);
                this.selectedTodoLeadId = lead.Id;
            }

            let todo = await this.createTodoEntity();
            let hasAttachmentError = await this.handleAttachmentUpload(todo.Uuid, EntityTypeId.TodoEntity);
            this.toggleCreateTodo();
            await this.createTodoRefLinks(sender, todo);

            await this.addTodoCategory(todo.Uuid);

            await this.refreshPlugin();
            this.showTodoCreatedNotification(hasAttachmentError);
        }
    };

    private setNewLinks = async (email: string) => {
        this.set(
            "newContactLink",
            email ? `${Network.BaseURL}PersonDetail/NewFromEmail?email=${email}` : `${Network.BaseURL}PersonDetail/Details/0?editMode=True`
        );
        this.set(
            "newLeadLink",
            email ? `${Network.BaseURL}LeadDetail/NewFromEmail?email=${email}` : `${Network.BaseURL}LeadDetail/Details/0?editMode=True`
        );
        this.set(
            "newTodoLink",
            email ? `${Network.BaseURL}TodoDetail/NewFromEmail?email=${email}` : `${Network.BaseURL}TodoDetail/Details/0?editMode=True`
        );
    };

    public filterInstitutionLeadsForTodos = e => {
        this.set("filterInstLeads", e.checked);
        this.leadSearchEnabledForTodos = true;
        this.get<any>("openLeadsForTodos").filter(this.getFilterForLeadsinTodos());
    };

    public filterInstitutionLeadsForContactNotes = e => {
        this.set("filterInstLeads", e.checked);
        this.leadSearchEnabledForContactNotes = true;
        this.get<any>("openLeadsForContactNotes").filter(this.getFilterForLeadsInContactNotes());
    };

    public instFilterVisible = (): boolean => {
        return this.get("sender") && this.get<SenderModel>("sender").HasInstitution && !this.get("hasAttachedContactLead");
    };

    public instFilterTodoVisible = (): boolean => {
        return this.get("sender") && this.get<SenderModel>("sender").HasInstitution && !this.get("hasAttachedTodoLead");
    };

    public async selectPersons() {
        if (this.get<SenderModel>("sender").HasInstitution) {
            let relations = await this.ptirService.getRelationsByInstitutionId(this.get<SenderModel>("sender").Institution.Id);
            let result = await this.relationChooser(relations);
            this.load(null, result.PersonId, result.InstitutionId);
        }
    }

    public async selectInstitutions() {
        if (this.get<SenderModel>("sender").HasPerson) {
            let relations = await this.ptirService.getRelationsByPersonId(this.get<SenderModel>("sender").Person.Id);
            let result = await this.relationChooser(relations);
            this.load(null, result.PersonId, result.InstitutionId);
        }
    }

    public async selectPerson() {
        if (this.get<SenderModel>("sender").HasPerson) {
            await this.load(null, this.get<SenderModel>("sender").Person.Id, null);
        }
    }

    public async selectInstitution() {
        if (this.get<SenderModel>("sender").HasInstitution) {
            await this.load(null, null, this.get<SenderModel>("sender").Institution.Id);
        }
    }

    private async loadData(emailAddress: string, personId?: number, institutionId?: number) {
        if (institutionId && personId) {
            const ptirs = await this.ptirService.getRelationByPersonInstitutionId(personId, institutionId);
            let ptir: PersonToInstitutionRelationModel;
            if (ptirs.length > 1) {
                ptir = await this.relationChooser(ptirs);
            } else {
                ptir = Util.firstOrDefault(ptirs);
            }
            await this.loadPersonInstitutionRelationData(ptir);
        } else if (institutionId) {
            await this.loadInstitutionData(institutionId);
        } else if (personId) {
            await this.loadPersonData(personId);
        } else if (emailAddress) {
            let ptir: PersonToInstitutionRelationModel;
            const ptirs = await this.ptirService.getPersonToInstitutionRelationsByEmail(emailAddress);
            if (ptirs.length > 1) {
                ptir = await this.relationChooser(ptirs);
            } else {
                ptir = Util.firstOrDefault(ptirs);
            }
            if (!!ptir) {
                await this.loadPersonInstitutionRelationData(ptir);
            } else {
                const instId = await this.institutionService.findInstitutionByEmail(emailAddress);
                if (instId) {
                    await this.loadInstitutionData(instId);
                } else {
                    const personId = await this.personService.findPersonByEmailAddress(emailAddress);
                    if (personId) {
                        await this.loadPersonData(personId);
                    }
                }
            }
        }
    }

    private async loadPersonData(personId: number) {
        this.set("sender", await this.senderModelService.getPersonSenderModelById(personId));
        let leadQuery = `PersonId eq ${personId}`;
        let todoQuery = `Persons/any(p:p/Id eq ${personId})`;
        let contactNoteQuery = `Persons/any(i:i/Id eq ${personId})`;
        this.loadRelatedLeadsTodosAndContactNotes(leadQuery, todoQuery, contactNoteQuery);
    }

    private async loadInstitutionData(instId: number) {
        this.set("sender", await this.senderModelService.getInstitutionSenderModel(instId));
        let leadQuery = `InstitutionId eq ${instId}`;
        let todoQuery = `Institutions/any(i: i/Id eq ${instId})`;
        let contactNoteQuery = `Institutions/any(i:i/Id eq ${instId})`;
        this.loadRelatedLeadsTodosAndContactNotes(leadQuery, todoQuery, contactNoteQuery);
    }

    private async loadPersonInstitutionRelationData(ptir: PersonToInstitutionRelationModel) {
        this.set("sender", await this.senderModelService.getSenderModel(ptir.PersonId, ptir.InstitutionId));
        let leadQuery = `PersonId eq ${ptir.PersonId} and InstitutionId eq ${ptir.InstitutionId}`;
        let todoQuery = `PersonToInstitutionRelations/any(pr:pr/PersonId eq ${ptir.PersonId} and pr/InstitutionId eq ${ptir.InstitutionId})`;
        let contactNoteQuery = `PersonToInstitutionRelations/any(pr:pr/PersonId eq ${ptir.PersonId} and pr/InstitutionId eq ${ptir.InstitutionId})`;
        this.set("relationName", ptir.RelationName);
        this.loadRelatedLeadsTodosAndContactNotes(leadQuery, todoQuery, contactNoteQuery);
    }

    private loadRelatedLeadsTodosAndContactNotes(leadQuery: string, todoQuery: string, contactNoteQuery: string) {
        if (!!this.get("sender")) {
            this.set("sender.Leads", this.getLeadDataSource(leadQuery));
            this.set("sender.Todos", this.getTodoDataSource(todoQuery));
            this.set("sender.ContactNotes", this.getContactNoteDataSource(contactNoteQuery));
        }
    }

    public listviewDatabound() {
        BaseModel.localize();
    }
    private async createLeadEntityFromContactNote(sender: SenderModel) {
        let lead = new LeadEntity();
        lead.Titel = this.get("contactNoteLeadCreateTitle");
        lead.ContactPersonId = this.get("contactNoteLeadCreateVerantwortlich");
        lead.CurrencyId = this.currencies.find(r => r.IsMainCurrency).Id;
        if (this.get("contactNoteLeadCreatePrice")) {
            lead.Price = parseFloat(this.get("contactNoteLeadCreatePrice"));
        } else {
            lead.Price = 0.0;
        }

        lead.DueDate = this.get("contactNoteLeadCreateDueDate");

        if (sender.HasPerson) {
            lead.PersonId = sender.Person.Id;
        }
        if (sender.HasInstitution) {
            lead.InstitutionId = sender.Institution.Id;
        }

        lead = await this.leadService.save(lead);
        return lead;
    }

    private async createLeadEntityFromTodo(sender: SenderModel): Promise<LeadEntity> {
        let lead = new LeadEntity();
        lead.Titel = this.get("todoLeadCreateTitle");
        lead.ContactPersonId = this.get("todoLeadCreateVerantwortlich");
        lead.CurrencyId = this.currencies.find(r => r.IsMainCurrency).Id;
        if (this.get("todoLeadCreatePrice")) {
            lead.Price = parseFloat(this.get("todoLeadCreatePrice"));
        } else {
            lead.Price = 0.0;
        }
        lead.DueDate = this.get("todoLeadCreateDueDate");

        if (sender.HasPerson) {
            lead.PersonId = sender.Person.Id;
        }
        if (sender.HasInstitution) {
            lead.InstitutionId = sender.Institution.Id;
        }
        lead = await this.leadService.save(lead);
        return lead;
    }

    protected async createTodoEntity(): Promise<TodoEntity> {
        let todo = new TodoEntity();
        todo.Titel = this.get("newTodoTitle");
        todo.Priority = this.get("todoPriority");
        todo.TodoOwners = this.get("todoVerantwortlicheSelected");
        todo.Start = this.get("todoStartDateTime");
        todo.End = this.get("todoEndDateTime");
        todo.Beschreibung = this.get<string>("todoDescription").replace(/[\n]+/gm, "<br/>");
        todo.LeadId = this.selectedTodoLeadId;
        todo.ReminderEnabled = this.get("reminderActive");
        if (todo.ReminderEnabled) {
            todo.ReminderSeconds = this.get<number>("reminderSeconds") * this.get<number>("reminderFactorValue");
        }
        todo = await this.todoService.save(todo);
        return todo;
    }

    protected async createContactNoteEntity(id?: number): Promise<ContactNoteEntity> {
        let contactNote = new ContactNoteEntity();
        contactNote.Id = id;
        contactNote.Subject = this.get("newNotesubject");
        contactNote.Date = new Date(this.get("newNoteDateTime")).toISOString();
        contactNote.Mood = this.get("newNoteMood");
        contactNote.ContactTypeId = parseInt(this.get("newNoteAkquiseTypeId"));
        contactNote.Note = this.get<string>("newNoteNote").replace(/[\n]+/gm, "<br/>");
        contactNote.LeadId = this.selectedContactNotesLeadId;
        contactNote = await this.contactNoteService.save(contactNote);
        return contactNote;
    }

    public hideSearchGroup = e => {
        const headerElement = $(e.target).closest(".searchListViewHeaderSelector");
        const list = $(`.${headerElement.data("kind")}`).closest(".km-list");
        const icon = headerElement.find(".listVisibleIndicator");
        list.toggle();
        if (list.is(":visible")) {
            icon.removeClass("fa-chevron-down");
            icon.addClass("fa-chevron-up");
        } else {
            icon.removeClass("fa-chevron-up");
            icon.addClass("fa-chevron-down");
        }
    };

    public manualSelectContact = async (e): Promise<void> => {
        if (e.dataItem) {
            this.set("searchVisible", false);
            if (e.dataItem.EntityTypeId === EntityTypeId.InstitutionEntity) {
                await this.load(null, null, e.dataItem.EntityId);
            } else if (e.dataItem.EntityTypeId === EntityTypeId.PersonEntity) {
                let privateRelation = new PersonToInstitutionRelationEntity();
                privateRelation.InstitutionId = null;
                privateRelation.PersonId = e.dataItem.EntityId;
                privateRelation.Relation = new PersonToInstRelationTypeEntity({
                    TranslatedDisplayName: "",
                });
                privateRelation.InstitutionDisplayName = "";
                privateRelation.PersonDisplayName = e.dataItem.Text;

                let relations = await this.ptirService.getRelationsByPersonId(e.dataItem.EntityId);
                relations.push(new PersonToInstitutionRelationModel(privateRelation, await this.contactMediaTypeService.getItems()));
                let institutionId = null;
                if (relations && relations.length > 1) {
                    const ptir = await this.relationChooser(relations);
                    await this.load(null, ptir.PersonId, ptir.InstitutionId);
                } else {
                    await this.load(null, e.dataItem.EntityId, institutionId);
                }
            }
        }
    };

    public searchListViewDataBound = async e => {
        for (const item of e.sender.dataSource.data()) {
            item.set("RenderImage", await this.avatarService.getAvatar(item.EntityId, item.EntityTypeId));
        }
        kendo.ui.progress($("#searchListView"), false);
    };

    protected async setInitialTodoGuiValues() {
        this.set("newTodoTitle", await this.getSubject());
        this.set("todoPriority", "Prio0");
        this.set("todoVerantwortlicheSelected", []);
        this.set("todoAttachementsSelected", []);
        this.set("todoStartDateTime", await this.getMailboxItemStartDate());
        this.set("todoEndDateTime", await this.getMailboxItemEndDate());
        this.set("todoDescription", "");
        this.set("reminderActive", false);
        this.set(
            "reminderFactorValue",
            this.get<{ name: string; factor: number }[]>("reminderFactor").find(x => x.name === BaseModel.t("day", { count: 0 })).factor
        );
        this.set("reminderSeconds", 0);
    }

    private async setInitialLeadGuiValues() {
        let userId = (await this.getMe()).UserId;
        this.set("todoLeadCreateTitle", "");
        this.set("todoLeadCreateVerantwortlich", userId);
        this.set("todoLeadCreatePrice", "0.00");
        this.set("todoLeadCreateDueDate", "");

        this.set("contactNoteLeadCreateTitle", "");
        this.set("contactNoteLeadCreateVerantwortlich", userId);
        this.set("contactNoteLeadCreatePrice", "0.00");
        this.set("contactNoteLeadCreateDueDate", "");
    }

    protected async setInitialContactNotesGuiValues() {
        this.set("newNotesubject", await this.getSubject());
        this.set("newNoteMood", "Happy");
        this.set("newNoteAkquiseTypeId", 4); //default to email
        this.set("newNoteNote", "");
        this.set("attachementsSelected", []);
        this.set("newNoteDateTime", await this.getMailboxItemStartDate());
    }

    protected async getMailboxItemStartDate(): Promise<Date> {
        return new Date();
    }

    protected async getMailboxItemEndDate(): Promise<Date | string> {
        return "";
    }

    protected showContactNoteCreatedNotification(hasAttachmentError: boolean) {
        if (hasAttachmentError) {
            this.showNotification(BaseModel.t("notification-contact-note-saved-attachments-not-supportet"), "warning");
        } else {
            this.showNotification(BaseModel.t("notification-contact-note-saved"), "success");
        }
    }

    protected showTodoCreatedNotification(hasAttachmentError: boolean) {
        if (hasAttachmentError) {
            this.showNotification(BaseModel.t("notification-todo-saved-attachments-not-supportet"), "warning");
        } else {
            this.showNotification(BaseModel.t("notification-todo-saved"), "success");
        }
    }

    protected showNotification = (message: string, level: "warning" | "success") => {
        $("#popupNotification")
            .kendoNotification({
                autoHideAfter: 3000,
                position: { top: 35, left: 0 },
                width: $("body").width(),
            })
            .data("kendoNotification")
            .show(message, level);
    };

    protected async createTodoRefLinks(sender: SenderModel, todo: TodoEntity) {
        if (sender.HasInstitution && sender.HasPerson) {
            await this.todoService.linkPersonToInstitutionEntry(todo.Id, sender.Person.Id, sender.Institution.Id);
        } else if (sender.HasInstitution) {
            await this.todoService.linkInstitutionEntry(todo.Id, sender.Institution.Id);
        } else if (sender.HasPerson) {
            await this.todoService.linkPersonEntry(todo.Id, sender.Person.Id);
        }
    }

    protected async createNoteRefLinks(sender: SenderModel, contactNote: ContactNoteEntity) {
        if (sender.HasInstitution && sender.HasPerson) {
            await this.contactNoteService.linkPersonToInstitutionEntry(contactNote.Id, sender.Person.Id, sender.Institution.Id);
        } else if (sender.HasInstitution) {
            await this.contactNoteService.linkInstitutionEntry(contactNote.Id, sender.Institution.Id);
        } else if (sender.HasPerson) {
            await this.contactNoteService.linkPersonEntry(contactNote.Id, sender.Person.Id);
        }
    }

    protected getSender = (): SenderModel => {
        return this.get("sender") as SenderModel;
    };
}
