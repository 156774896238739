import Network from "./network";
import ClientOAuth2 from "client-oauth2";

export default class OrphyNetwork extends Network {
    private static token: string;

    private static orphyAuth: ClientOAuth2 = new ClientOAuth2({
        clientId: "OrphyPluginOutlook",
        authorizationUri: `${Network.IDSRV_URL}/connect/authorize`,
        redirectUri: `${Network.OUTLOOK_REDIRECT}index.html`,
        scopes: ["orphy_odata_api", "orphy_public_api"],
    });

    public static isAuthenticated = (): boolean => {
        if (OrphyNetwork.token) {
            return true;
        }
        return false;
    };

    public static handleRedirect = (gotTokenCallback: Function, gotNoTokenCallback: Function) => {
        OrphyNetwork.orphyAuth.token
            .getToken(window.location)
            .then(tokenResponse => {
                OrphyNetwork.token = tokenResponse.accessToken;
                gotTokenCallback();
            })
            .catch(() => {
                gotNoTokenCallback();
            });
    };

    public authenticate = async (): Promise<{ token_type: string; access_token: string }> => {
        if (!OrphyNetwork.isAuthenticated()) {
            window.location.href = OrphyNetwork.orphyAuth.token.getUri();
        } else {
            return { token_type: "Bearer", access_token: OrphyNetwork.token };
        }
    };

    public signout = async () => {
    };
}
